<template>
    <div>
        <v-flex xs12 sm8 offset-sm-2 class="my-4 px-8">
            <v-card class="white--text mb-1 mt-8 " flat :tile="true">
                <v-btn
                        fab
                        small
                        color="primary accent-2"
                        bottom
                        right
                        absolute
                        @click="goHome"
                >
                    <v-icon>mdi-home</v-icon>
                </v-btn>

                <v-container fluid grid-list-lg>
                    <v-layout row>
                        <v-flex xs7>
                            <div>
                                <div class="display-1 black--text text--darken-1">Returns and Refunds Policy</div>
                                <!--                <div class="grey&#45;&#45;text">-->
                                <!--                    Your one stop shop for all poultry products-->
                                <!--                </div>-->
                            </div>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-card>

            <v-card color="white mb-15" flat class="white--text" height="100%">

                <!--comes here-->
                <v-card flat>
                    <v-card-text>
                        <!--//Tab contents-->
                        <v-container fluid grid-list-lg class="">
                            <v-layout row>
                                <v-flex xs12>
                                    <div class="">
                                        <div class="headline primary--text"></div>
                                        <blockquote class="grey--text text--darken-1">
                                            Thank you for shopping at akokomarket.com. We strive to provide our customers 
                                            with high-quality products and exceptional service. However, we understand that 
                                            sometimes you may need to return or exchange an item. This Returns and Refunds Policy 
                                            outlines the guidelines and procedures for returns and refunds on purchases made through our website.

                                            <v-divider class="my-4"></v-divider>

                                            <div class="headline primary--text"></div>
                                            Eligibility for Returns and Refunds:
                                            <br/>
                                            ~ 1.1. Damaged or Defective Items: If you receive a damaged or defective item, please contact 
                                            our customer support within 24 hours of receiving the product. We will 
                                            provide instructions on how to return the item for a refund or exchange.

                                            <br/>
                                            ~ 1.2. Incorrect Item or Size: If you receive an incorrect item or size different from what you ordered, 
                                            please contact our customer support within [number of days] of receiving the product. We will arrange for 
                                            the correct item or size to be sent to you, or provide a refund if the item is no longer available.
                                            <br/>

                                            ~ 1.3. Change of Mind: We understand that preferences may change, and we want you to be satisfied 
                                            with your purchase. If you change your mind about an item, you may return it within 12 hours 
                                            of receiving the product, provided that the item is in its original condition, unused, and with all tags 
                                            and packaging intact. In such cases, you will be eligible for a refund, minus any delivery costs.

                                            <br/>
                                            <br/>
                                            Return Procedure:
                                            <br/>
                                            ~ 2.1. To initiate a return, please contact our customer support by calling <a href="callto:+233551444677">+233 55 144 4677</a>. 
                                            Please provide your invoice number, the reason for the return, and any relevant details. Our customer support 
                                            team will guide you through the return process.
                                            <br/>

                                            ~ 2.2. If the return is approved, you will receive a Return Merchandise Authorization (RMA) number along with 
                                            instructions on how to deliver the item back to us. Please ensure that the item is securely packaged to avoid 
                                            any damage during transit.
                                            <br/>
                                            ~ 2.3. You are responsible for the return delivery costs, unless the return is due to an error on our part 
                                            (e.g., damaged item, incorrect size). We recommend using a trackable delivery method to ensure that the item 
                                            reaches us safely.

                                            <br/>
                                            <br/>
                                            Refund and Exchange:
                                            <br/>
                                            ~ 3.1. Refunds: Once we receive the returned item and verify its condition, we will process the refund within 
                                            three (3) working days. The refund will be issued to the original payment method used for the purchase or directed by you. 
                                            Please note that depending on your financial institution, it may take additional time for the refund to be reflected in your account.
                                            <br/>
                                            ~ 3.2. Store Credit: If you opt for store credit instead of a refund, we will issue the credit to your akokomarket.com
                                            account within three (3) working days after receiving the returned item. 
                                            The store credit can be used towards future purchases on our website.
                                            <br/>

                                            ~ 3.3. Exchanges: If you requested an exchange for a different size or item, we will process the exchange
                                            once we receive the returned item. We will delivery the requested replacement to you, subject to availability. 
                                            If the requested item is no longer available, we will offer a refund or store credit as an alternative.

                                            <br/>
                                            <br/>
                                            Exceptions and Limitations:
                                            <br/>

                                            ~ 4.1. The following items are not eligible for returns or refunds, except in cases of damage or defects:
                                            Damages or defects caused by actions or inactions of the customer
                                            <br/>

                                            ~ 4.2. We reserve the right to refuse returns or refunds if the item is not in its original condition
                                        </blockquote>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-card>
        </v-flex>
    </div>
</template>

<script>
    export default {
        name: "returns",

        data: () => ({
            text: "",
        }),

        methods: {
            goHome() {
                this.$router.push("/");
            },
            addItem(item) {
                const removed = this.items.splice(0, 1);
                this.items.push(...this.more.splice(this.more.indexOf(item), 1));
                this.more.push(...removed);
                this.$nextTick(() => {
                    this.currentItem = "tab-" + item;
                });
                alert(this.currentItem);
            }
        },
        created() {},
        components: {}
    };
</script>

<style scoped></style>
